import React from "react";
import { Col, Input, Label, FormFeedback } from "reactstrap";

const TextField = (props) => {
  return (
    <Col md={props.md}>
      <div className={props.mb}>
        <Label className="form-label" htmlFor="product-title-input">
          {props.label} {props.optional && <span className="text-muted">(Optional)</span>}
        </Label>
        <Input
          type={props.type || "text"}
          name={props.name}
          className={`${props.inputClass} form-control`}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          invalid={
            props.validation.touched[props.name] && props.validation.errors[props.name]
              ? true
              : false || (props.errorMessage && props.errorMessage[props.name] && props.errorMessage[props.name].length)
              ? true
              : false
          }
          placeholder={props.placeholder || props.label}
          readOnly={props.readOnly}
        />
        {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
        ) : null}

        {props.errorMessage && props.errorMessage[props.name] && props.errorMessage[props.name].length > 0 ? (
          <FormFeedback type="invalid">
            <div>{props.errorMessage[props.name]}</div>
          </FormFeedback>
        ) : (
          ""
        )}
      </div>
    </Col>
  );
};

export default TextField;
