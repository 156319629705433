import React from "react";
import { Col, Label, FormFeedback } from "reactstrap";
// import IntlTelInput from "react-intl-tel-input";
// import "react-intl-tel-input/dist/main.css";

//
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const IntNumberInput = ({ ...props }) => {
  return (
    <Col>
      <Label htmlFor={props.id} className="form-label">
        {props.label}
      </Label>

      <div
        className={`${
          (props.validation.touched[props.name] && props.validation.errors[props.name]) ||
          (props.errorMessage && props.errorMessage[props.name])
            ? "form-control is-invalid"
            : ""
        } `}
      >
        <PhoneInput
          defaultCountry="gh"
          className={` `}
          inputClassName={`form-control ${props.inputClass}  py-4`}
          placeholder="Enter phone number"
          value={""}
          onChange={(phone) => {
            console.log(phone, "PHONNNEEE__VALUEE");
            props.validation.setFieldValue("phone", phone.slice(1));
          }}
        />
      </div>

      {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
        <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
      ) : null}

      {props.errorMessage && props.errorMessage[props.name] && props.errorMessage[props.name].length > 0 ? (
        <FormFeedback type="invalid">
          <div>{props.errorMessage[props.name]}</div>
        </FormFeedback>
      ) : (
        ""
      )}
    </Col>
  );
};

export default IntNumberInput;
