import React, { useEffect, useState } from "react";

import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// action
import Select from "react-select";
import { handleValidDate } from "../../Utility";

import { APIClient } from "../../utils/api_helper";
import MsgToast from "../../Components/Common/MsgToast";
import TextField from "../../Components/Common/Fields/TextField";
//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

//import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "./ParticlesAuth";
import SimplyHead from "../../Components/Common/SimplyHead";
import DateField from "../../Components/Common/Fields/DateField";
import LoginOtpModal from "./LoginOtpModal";
import CountrySelectField from "../../Components/Common/Fields/CountrySelect";
import StateSelectField from "../../Components/Common/Fields/StateSelect";
import IntNumberInput from "../../Components/Common/Fields/IntNumberInput";

const api = new APIClient();

const Register = () => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  // Border Top Nav
  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Proccessing fee...",
    message: "Proccessing please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-success",
  });

  const [activeVerticalTab, setactiveVerticalTab] = useState(7);
  const [passedverticalSteps, setPassedverticalSteps] = useState([1]);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [country, setCountry] = useState(null);
  const [currentState, setCurrentState] = useState(null);

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState(false);

  function toggleVerticalTab(tab) {
    console.log(tab, "CURRENT__TABBS");
    if (activeVerticalTab !== tab) {
      var modifiedSteps = [...passedverticalSteps, tab];

      if (tab >= 7 && tab <= 11) {
        setactiveVerticalTab(tab);
        setPassedverticalSteps(modifiedSteps);
      }
    }
  }

  console.log(country, currentState, "VERTICAL___");

  const accountValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      phone: "",
      email: "",
      password: "",
      confirm_password: "",
    },

    validationSchema: Yup.object({
      phone: Yup.string().required("Please Enter the your phone number"),
      email: Yup.string().required("Please your email"),
      password: Yup.string().required("Please your password password"),
      confirm_password: Yup.string().required("Please confirm your password"),
    }),

    onSubmit: async (values) => {
      try {
        setErrorMsg("");
        const data = await api.post("/account/register/verify-credentials/", values);

        console.log(data, "FEESS_DSATA");

        // setSuccessMsg(data.message);
        toggleVerticalTab(activeVerticalTab + 1);
        // setAlert({ ...alert, status: false });

        // setOpenOtpModal(true);
      } catch (error) {
        console.log(error, "LOGIN");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  const profileValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      phone: accountValidation.values.phone,
      email: accountValidation.values.email,
      password: accountValidation.values.password,
      confirm_password: accountValidation.values.confirm_password,

      first_name: "",
      last_name: "",
      middle_name: "",
      date_of_birth: "",
      city: "",
      address: "",
      country: country?.name,
      region: currentState?.name,
    },

    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter your first name"),
      last_name: Yup.string().required("Please enter your last year"),
      date_of_birth: Yup.string().required("Please enter your date of birth"),
      city: Yup.string().required("Please enter your city"),
      address: Yup.string().required("Please enter your address"),
      country: Yup.string().required("Please select your country"),
    }),

    onSubmit: async (values) => {
      console.log("CREATING FEES");
      setAlert({
        status: true,
        title: "Proccessing fee...",
        message: "Proccessing please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-success",
      });

      setErrorMsg("");
      try {
        const data = await api.post("/account/register/", values);

        console.log(data, "FEESS_DSATA");

        setSuccessMsg(data.message);
        setAlert({ ...alert, status: false });

        setOpenOtpModal(true);
      } catch (error) {
        console.log(error, "LOGIN");
        let errMsg = error?.response?.data?.message;
        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || "internal server occured";
        setErrorMsg(errMsg);

        setAlert({
          status: true,
          title: "Sorry!",
          message: errMsg,
          //class: "ri-information-line",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
          //back_url: "/accounts/plans",
          //// redirect user to the customer portal
          dismissable: true,
        });
        console.log(errMsg, "ER__MERSG");
      }
    },
  });

  console.log(profileValidation.values, "PROFILEE___VALIDATION");

  document.title = "SimplyPay | Sign UP";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="text-center mt-sm-3 text-white-50">
                  <div className="d-flex justify-content-center">
                    <SimplyHead />
                  </div>
                  <p className="fs-20 fw-bold">Simply Pay for anything, securely</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={6}>
                <Card className="">
                  <CardBody className="p-4">
                    <div className="text-center">
                      <h5 className="text-primary">Create New SimplyPay Account</h5>
                      {/* <p className="text-muted">Get your SimplyPay account now</p> */}
                    </div>
                    <div className="p-2">
                      <Col lg={12}>
                        <div className="px-lg-4">
                          <TabContent activeTab={activeVerticalTab}>
                            <TabPane tabId={7}>
                              {/* <div>
                                <h5>Account information</h5>
                                <p className="text-muted">Fill all information below</p>
                              </div> */}
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  accountValidation.handleSubmit();
                                }}
                                className="needs-validation"
                                action="#"
                              >
                                <div className="mb-3">
                                  <IntNumberInput
                                    md="6"
                                    mb="mb-3"
                                    inputClass="fs-18"
                                    onChange={accountValidation.handleChange}
                                    validation={accountValidation}
                                    onBlur={accountValidation.handleBlur}
                                    value={accountValidation.values.phone}
                                    label="Phone number"
                                    name="phone"
                                    // errorMessage={client_errors}
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label htmlFor="useremail" className="form-label">
                                    Email <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    id="email"
                                    name="email"
                                    className="form-control fs-18"
                                    placeholder="Enter email address"
                                    type="email"
                                    onChange={accountValidation.handleChange}
                                    onBlur={accountValidation.handleBlur}
                                    value={accountValidation.values.email || ""}
                                    invalid={
                                      accountValidation.touched.email && accountValidation.errors.email ? true : false
                                    }
                                  />
                                  {accountValidation.touched.email && accountValidation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      <div>{accountValidation.errors.email}</div>
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3">
                                  <Label htmlFor="userpassword" className="form-label">
                                    Password <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="password"
                                    type="password"
                                    className="form-control fs-18"
                                    placeholder="Enter Password"
                                    onChange={accountValidation.handleChange}
                                    onBlur={accountValidation.handleBlur}
                                    value={accountValidation.values.password || ""}
                                    invalid={
                                      accountValidation.touched.password && accountValidation.errors.password
                                        ? true
                                        : false
                                    }
                                  />
                                  {accountValidation.touched.password && accountValidation.errors.password ? (
                                    <FormFeedback type="invalid">
                                      <div>{accountValidation.errors.password}</div>
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-2">
                                  <Label htmlFor="confirmPassword" className="form-label">
                                    Confirm Password <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    className="form-control fs-18"
                                    name="confirm_password"
                                    type="password"
                                    placeholder="Confirm Password"
                                    onChange={accountValidation.handleChange}
                                    onBlur={accountValidation.handleBlur}
                                    value={accountValidation.values.confirm_password || ""}
                                    invalid={
                                      accountValidation.touched.confirm_password &&
                                      accountValidation.errors.confirm_password
                                        ? true
                                        : false
                                    }
                                  />
                                  {accountValidation.touched.confirm_password &&
                                  accountValidation.errors.confirm_password ? (
                                    <FormFeedback type="invalid">
                                      <div>{accountValidation.errors.confirm_password}</div>
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-4">
                                  <p className="mb-0 fs-12 text-muted fst-italic">
                                    By registering you agree to the SimplyPay &nbsp;
                                    <Link
                                      to="terms"
                                      className="text-primary text-decoration-underline fst-normal fw-medium"
                                    >
                                      Terms of Use
                                    </Link>
                                  </p>
                                </div>

                                <div className="mt-4">
                                  <button
                                    // onClick={() => {
                                    //   toggleVerticalTab(activeVerticalTab + 1);
                                    // }}
                                    className="btn btn-success w-100"
                                    type="submit"
                                    disabled={accountValidation.isSubmitting}
                                  >
                                    {accountValidation.isSubmitting ? "Please wait" : "Next"}
                                  </button>
                                </div>
                              </Form>
                            </TabPane>

                            <TabPane tabId={8}>
                              <div>
                                <h5>Profile information</h5>
                                <p className="text-muted">Fill in your profile information below</p>
                              </div>

                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  profileValidation.handleSubmit();
                                }}
                              >
                                <TabContent activeTab={topBorderTab} className="text-muted">
                                  <TabPane tabId="1" id="nav-border-justified-home">
                                    <Row>
                                      <Col md={8} lg={6}>
                                        <TextField
                                          md="12"
                                          mb="mb-3"
                                          value={profileValidation.values.first_name || ""}
                                          onChange={profileValidation.handleChange}
                                          validation={profileValidation}
                                          onBlur={profileValidation.handleBlur}
                                          placeholder="First name"
                                          label="First name"
                                          name="first_name"
                                        />
                                      </Col>

                                      <Col md={8} lg={6}>
                                        <TextField
                                          md="12"
                                          mb="mb-3"
                                          value={profileValidation.values.middle_name || ""}
                                          onChange={profileValidation.handleChange}
                                          validation={profileValidation}
                                          onBlur={profileValidation.handleBlur}
                                          placeholder="Middle name"
                                          label="Middle name"
                                          name="middle_name"
                                          optional
                                        />
                                      </Col>

                                      <Col md={8} lg={6}>
                                        <TextField
                                          md="12"
                                          mb="mb-3"
                                          value={profileValidation.values.last_name || ""}
                                          onChange={profileValidation.handleChange}
                                          validation={profileValidation}
                                          onBlur={profileValidation.handleBlur}
                                          placeholder="Last name"
                                          label="Last name"
                                          name="last_name"
                                        />
                                      </Col>

                                      <DateField
                                        md="6"
                                        value={profileValidation.values.date_of_birth || ""}
                                        // onChange={validation.handleChange}
                                        onChange={(e) => {
                                          const date = handleValidDate(e);
                                          console.log(date, "DATE__FIELDDDL");

                                          profileValidation.setFieldValue("date_of_birth", date);
                                        }}
                                        validation={profileValidation}
                                        onBlur={profileValidation.handleBlur}
                                        label="Date of birth"
                                        name="date_of_birth"
                                        // errorMessage={error && error.errors}
                                      />

                                      <CountrySelectField setCountry={setCountry} validation={profileValidation} />

                                      <Col md={8} lg={6}>
                                        <TextField
                                          md="12"
                                          mb="mb-3"
                                          value={profileValidation.values.city || ""}
                                          onChange={profileValidation.handleChange}
                                          validation={profileValidation}
                                          onBlur={profileValidation.handleBlur}
                                          placeholder="ebter city"
                                          label="City"
                                          name="city"
                                        />
                                      </Col>

                                      <Col md={8} lg={6}>
                                        <TextField
                                          md="12"
                                          mb="mb-3"
                                          value={profileValidation.values.address || ""}
                                          onChange={profileValidation.handleChange}
                                          validation={profileValidation}
                                          onBlur={profileValidation.handleBlur}
                                          placeholder="Select address"
                                          label="Enter your address"
                                          name="address"
                                        />
                                      </Col>
                                    </Row>
                                  </TabPane>
                                </TabContent>

                                <div className="d-flex align-items-start gap-3 mt-4">
                                  <button
                                    type="button"
                                    className="btn btn-light btn-label previestab"
                                    onClick={() => {
                                      toggleVerticalTab(activeVerticalTab - 1);
                                    }}
                                  >
                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to
                                    back
                                  </button>
                                  <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                    <i className="ri-send-plane-2-line label-icon align-middle fs-16 ms-2"></i>

                                    {profileValidation.isSubmitting ? "Submitting" : "Submit"}
                                  </button>
                                  {/* <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Pay Fee
                                </button> */}
                                </div>
                              </Form>
                            </TabPane>

                            <TabPane tabId={10}>
                              <div className="text-center pt-4 pb-2">
                                <div className="mb-4">
                                  <lord-icon
                                    src="https://cdn.lordicon.com/lupuorrc.json"
                                    trigger="loop"
                                    colors="primary:#0ab39c,secondary:#405189"
                                    style={{ width: "120px", height: "120px" }}
                                  ></lord-icon>
                                </div>
                                <h5>Your Order is Completed !</h5>
                                <p className="text-muted">
                                  You Will receive an order confirmation email with details of your order.
                                </p>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Already have an account ?{" "}
                    <Link to="/login" className="fw-semibold text-primary text-decoration-underline">
                      {" "}
                      Signin{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
            {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

            <LoginOtpModal
              openOtpModal={openOtpModal}
              setOpenOtpModal={setOpenOtpModal}
              email={accountValidation.values.email}
              urlPath="register"
            />
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
