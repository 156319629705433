import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";

import { Button, Form, FormFeedback, Input, Label, Alert } from "reactstrap";
// import Router from "next/router";
import AOS from "aos";
import "aos/dist/aos.css";

import { APIClient } from "../../utils/api_helper";
import { Link } from "react-router-dom";
import LoginOtpModal from "./LoginOtpModal";
import SimplyHead from "../../Components/Common/SimplyHead";

const api = new APIClient();

export default function Home() {
  const [passwordShown, setPasswordShown] = useState(false);

  const [errMsg, setErrorMsg] = useState("");

  const [successMsg, setSuccessMsg] = useState(false);

  const [openOtpModal, setOpenOtpModal] = useState(false);

  const [reference, setReference] = useState("");

  useEffect(() => {
    AOS.init();
  }, []);

  const togglePasswordVisiblity = () => setPasswordShown(passwordShown ? false : true);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",

      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Please enter a email"),
      password: Yup.string().required("Please enter a password"),
    }),

    onSubmit: async (values) => {
      console.log("sisisi");
      setErrorMsg("");
      setSuccessMsg("");
      try {
        const data = await api.post("/account/login/", values);

        console.log(data, "NEW_METAA");

        // const access_token = data.data.access_token;

        setSuccessMsg(data?.message || "success");

        // localStorage.setItem("access_token", access_token);

        setOpenOtpModal(true);

        // return Router.replace("/").then(() => Router.reload());
      } catch (error) {
        console.log(error?.isNetworkError, "LOGIN_ERROR");
        let errMsg = error?.response?.data?.message;

        let detail = error?.response?.data?.detail;

        console.log(errMsg, "ERRRRRRRRRRRR");
        errMsg = errMsg || detail || "internal server occured";

        if (error?.isNetworkError) {
          errMsg = "Please check your internet connection and try again";
        }

        setErrorMsg(errMsg);
        console.log(errMsg, "ER__MERSG");
      }
    },
  });
  document.title = "SimplyPay | log in";

  return (
    <section className="login__section">
      <div className="login">
        <div className="login__container">
          <SimplyHead />

          <p className="login__smalltext">Please fill your details to access your account.</p>

          {/* <div className="text-cente">
            <h5 className="login__head">
              <img src="/static/s-logo-orange.png" alt="" className="rounded-circle avatar-xl" width="200" />
              Simply Pay
            </h5>
            <p className="login__smalltext">Please fill your details to access your account.</p>
          </div> */}

          <Alert color="success">
            {/* <div>{errMsg} </div> */}
            Type your credentials
          </Alert>

          <div className="login__formcontainer">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
              className="login__form"
            >
              <div className="login__group">
                <Label htmlFor="email" className="login__label">
                  Email
                </Label>
                <Input
                  name="email"
                  className="login__input fs-18"
                  placeholder="Enter email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={validation.touched.email && validation.errors.email ? true : false}
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                ) : null}
              </div>

              <div className="login__group">
                <Label className="login__label" htmlFor="password">
                  Password
                </Label>
                <Input
                  className="login__input fs-18"
                  name="password"
                  value={validation.values.password || ""}
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={validation.touched.password && validation.errors.password ? true : false}
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                ) : null}
                {/* <button
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                >
                  <i
                    onClick={togglePasswordVisiblity}
                    className="ri-eye-fill align-middle"
                  ></i>
                </button> */}
              </div>

              <Button color="success" disabled={validation.isSubmitting} className="login__button" type="submit">
                {!validation.isSubmitting ? "Sign in" : "Signing in, please wait"}
              </Button>
            </Form>
          </div>
          <div className="login__remembermecontainer">
            <div className="login__rememberme">
              <Link to="/forgot-password" className="d-inline-block auth-logo">
                forgot password ?
              </Link>
            </div>
          </div>
        </div>

        <div data-aos="fade-left" className="login__side">
          <img src="/static/loginside.png" alt="" />
        </div>
      </div>

      {errMsg ? <MsgToast msg={errMsg} color="danger" icon="ri-checkbox-circle-line" /> : null}

      {successMsg ? <MsgToast msg={successMsg} color="success" icon="ri-checkbox-circle-line" /> : null}

      <LoginOtpModal
        openOtpModal={openOtpModal}
        setOpenOtpModal={setOpenOtpModal}
        email={validation.values.email}
        urlPath="login"
      />
    </section>
  );
}
