import React, { useState } from "react";

//Import Breadcrumb
import Cleave from "cleave.js/react";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Row,
  Label,
  Input,
  Button,
  Spinner,
  ModalFooter,
  Col,
  Alert,
  AccordionItem,
  Accordion,
  Collapse,
  Container,
} from "reactstrap";
import SimpleBar from "simplebar-react";

import classnames from "classnames";

// Import Content

//import Components

// import {
//   DefaultAccordionExample,
//   FlushAccordionExample,
//   IconAccordionExample,
//   WithIconAccordionExample,
//   PlusIconAccordionExample,
//   LeftIconAccordionExample,
//   BorderedAccordionExample,
//   NestingAccordionExample,
//   FillColoredAccordionExample,
//   CollapseExample,
//   HorizontalCollapseExample,
//   IconCollapseExample,
//   InlineBLockCollapseExample,
//   MultipleTargetCollapseExample,
// } from "./UiAccordion&CollapseCode";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { APIClient } from "../../../utils/api_helper";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import SendMoneyModal from "../SendMoneyModal";
import ProccessingLoader from "../../../Components/Loaders/ProccessingLoader";

const api = new APIClient();

const TransferReceivers = ({ setRecipient, validation, data, mutate }) => {
  document.title = "";
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrorMsg] = useState("");

  // Default Accordion
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
  };

  const [alert, setAlert] = useState({
    status: false,
    title: "Checking user...",
    message: "Checking user, please wait...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  console.log(data, "RECEIVERSSSSS");

  const deleteReceiver = async (uuid) => {
    try {
      setAlert({
        status: true,
        title: "Deleting...",
        message: "Deleting please wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-primary",
      });

      setErrorMsg("");
      setSuccessMsg("");
      console.log(uuid, "VALUESS__REQUESTTS");
      const data = await api.delete("/transaction/transfer/recent/", {
        params: {
          uuid,
        },
      });
      console.log(data, "REQUEST__STATS__DATAA");

      // setRequestSuccess(data.message);
      mutate();

      setSuccessMsg(data.message);
      setErrorMsg("");

      setAlert({
        status: true,
        title: (
          <span className={""}>
            <i className={`text-success bx`}>TRANSFER SUCCESFULL</i>
          </span>
        ),

        message: data.message,
        // class: "spinner-border spinner-border-lg",
        back_url: "/deposit-money",
        goto_url: "/transactions",
        icon: (
          <lord-icon
            src="https://cdn.lordicon.com/tqywkdcz.json"
            style={{ width: "150px", height: "150px" }}
            trigger="loop"
            colors="primary:#f7b84b,secondary:#405189"
          ></lord-icon>
        ),
      });
      // setTimeout(() => {
      //  }, 2000);
    } catch (error) {
      setAlert({ ...alert, status: false });

      console.log(error, "LOGIN");
      setErrorMsg("");

      let errMsg = error?.response?.data?.message;

      console.log(errMsg, "ERRRRRRRRRRRR");
      errMsg = errMsg || "internal server occured";

      setErrorMsg(errMsg);

      console.log(errMsg, "ER__MERSG");
    }
  };

  return (
    <React.Fragment>
      <Col>
        <Card className={"bg-color-info"}>
          {/* <div className="flex-shrink-0 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-soft-info btn-sm  "
              data-bs-toggle="modal"
              data-bs-target="#inviteMembersModal"
            >
              <i className="ri-share-line me-1 align-bottom"></i> recent reciepient
            </button>
          </div> */}

          <div className="mt-1">
            <CardBody>
              {/* <p className="text-muted"> recent reciepient</p> */}
              <div className="live-preview">
                <Accordion id="default-accordion-example">
                  <AccordionItem>
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classnames("accordion-button", { collapsed: !col1 })}
                        type="button"
                        onClick={t_col1}
                        style={{ cursor: "pointer" }}
                      >
                        Recent receivers
                      </button>
                    </h2>

                    <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne">
                      <SimpleBar data-simplebar style={{ height: "196px" }} className="">
                        <div className="vstack gap-3 px-2">
                          {data && data?.recent?.length ? (
                            data?.recent?.map((receiver, i) => {
                              return (
                                <div
                                  key={i}
                                  className="d-flex align-items-center bg-light border border-dark shadow  px-1 rounded"
                                >
                                  <div className="d-flex align-items-center  avatar-sm   me-3">
                                    <img
                                      height="50"
                                      src={receiver?.receiver?.face_id}
                                      alt=""
                                      className="avatar-sm rounded-circle"
                                    />
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="fs-13 mb-0">
                                      <Link to="#" className="text-body d-block">
                                        {receiver?.receiver?.first_name} {receiver?.receiver?.last_name}
                                      </Link>
                                    </h5>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="d-flex align-items-center gap-1">
                                      <button
                                        type="button"
                                        className="btn btn-soft-info btn-sm "
                                        onClick={() => {
                                          validation.setFieldValue("recipient", receiver?.receiver?.uuid);
                                          setRecipient(receiver.receiver);
                                        }}
                                      >
                                        Send money
                                      </button>
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="button"
                                          className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                        >
                                          <i className="ri-more-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <li>
                                            <DropdownItem onClick={() => deleteReceiver(receiver.uuid)}>
                                              <i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>
                                              Delete
                                            </DropdownItem>
                                          </li>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <Alert
                              color="info"
                              className="mb-0 d-flex justify-content-center mdi mdi-account-remove-outline"
                            >
                              Your have no saved recent recipient yet
                            </Alert>
                          )}
                        </div>
                      </SimpleBar>
                    </Collapse>
                  </AccordionItem>
                  <AccordionItem>
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className={classnames("accordion-button", { collapsed: !col2 })}
                        type="button"
                        onClick={t_col2}
                        style={{ cursor: "pointer" }}
                      >
                        Frequent receivers
                      </button>
                    </h2>

                    <Collapse isOpen={col2} className="accordion-collapse">
                      <SimpleBar data-simplebar style={{ height: "196px" }} className="">
                        <div className="vstack gap-3 px-2">
                          {data && data?.frequent.length ? (
                            data?.frequent?.map((receiver, i) => {
                              return (
                                <div
                                  key={i}
                                  className="d-flex align-items-center bg-light border border-dark shadow  px-1 rounded"
                                >
                                  <div className="d-flex align-items-center  avatar-sm   me-3">
                                    <img
                                      height="50"
                                      src={receiver?.receiver?.face_id}
                                      alt=""
                                      className="avatar-sm rounded-circle"
                                    />
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="fs-13 mb-0">
                                      <Link to="#" className="text-body d-block">
                                        {receiver?.receiver?.first_name} {receiver?.receiver?.last_name}
                                      </Link>
                                    </h5>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="d-flex align-items-center gap-1">
                                      <button
                                        type="button"
                                        className="btn btn-soft-info btn-sm "
                                        onClick={() => {
                                          validation.setFieldValue("recipient", receiver?.receiver?.uuid);
                                          setRecipient(receiver.receiver);
                                        }}
                                      >
                                        Send money
                                      </button>
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="button"
                                          className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                        >
                                          <i className="ri-more-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <li>
                                            <DropdownItem onClick={() => deleteReceiver(receiver.uuid)}>
                                              <i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>
                                              Delete
                                            </DropdownItem>
                                          </li>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <Alert
                              color="info"
                              className="mb-0 d-flex justify-content-center mdi mdi-account-remove-outline"
                            >
                              Your have no saved recent recipient yet
                            </Alert>
                          )}
                        </div>
                      </SimpleBar>
                    </Collapse>
                  </AccordionItem>
                </Accordion>
              </div>
            </CardBody>

            {/* <SimpleBar data-simplebar style={{ height: "196px" }} className="">
              <div className="vstack gap-3 px-2">
                {data && data?.length ? (
                  data.map((receiver, i) => {
                    return (
                      <div
                        key={i}
                        className="d-flex align-items-center bg-light border border-dark shadow  px-1 rounded"
                      >
                        <div className="d-flex align-items-center  avatar-sm   me-3">
                          <img
                            height="50"
                            src={receiver?.receiver?.face_id}
                            alt=""
                            className="avatar-sm rounded-circle"
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="fs-13 mb-0">
                            <Link to="#" className="text-body d-block">
                              {receiver?.receiver?.first_name} {receiver?.receiver?.last_name}
                            </Link>
                          </h5>
                        </div>
                        <div className="flex-shrink-0">
                          <div className="d-flex align-items-center gap-1">
                            <button
                              type="button"
                              className="btn btn-soft-info btn-sm "
                              onClick={() => {
                                validation.setFieldValue("recipient", receiver?.receiver?.uuid);
                                setRecipient(receiver.receiver);
                              }}
                            >
                              Send money
                            </button>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="button" className="btn btn-icon btn-sm fs-16 text-muted dropdown">
                                <i className="ri-more-fill"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <li>
                                  <DropdownItem onClick={() => deleteReceiver(receiver.uuid)}>
                                    <i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Delete
                                  </DropdownItem>
                                </li>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Alert color="info" className="mb-0 d-flex justify-content-center mdi mdi-account-remove-outline">
                    Your have no saved recent recipient yet
                  </Alert>
                )}
              </div>
            </SimpleBar> */}
          </div>
        </Card>
      </Col>

      <ProccessingLoader alert={alert} setAlert={setAlert} />
    </React.Fragment>
  );
};

export default TransferReceivers;
